import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Row, Col, Image } from "react-bootstrap"

import unentretienavecunchat1 from "./../images/un entretien avec un chat, postcards/un entretien avec un chat, postcards1.jpg"
import unentretienavecunchat2 from "./../images/un entretien avec un chat, postcards/un entretien avec un chat, postcards2.jpg"
import unentretienavecunchat3 from "./../images/un entretien avec un chat, postcards/un entretien avec un chat, postcards3.jpg"

const UnEntretienAvecUnChat = ({location, id}) => (
    <Layout>
        <Seo title="Un Entretien Avec Un Chat" description="Un Entretien Avec Un Chat, By Manon Lambeens" />
        <Row className="">
            <Col xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-0 ps-0 mt-1">
              <h1 className="text-uppercase fs-4 text-dark">un entretien avec un chat, postcards</h1>
                <p className="textAbout">
                     A series of postcards based on Marcel Broodthaers' entretien avec un chat. The postcards are all hand drawn. 
                </p>
            </Col>
        </Row>
        <Row className="text-center p-0">
        <Col  xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-0 ps-0 mt-2">
          <Image src={unentretienavecunchat3} fluid style={{width: '100%'}} alt="Un Entretien Avec Un Chat, Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="text-center p-0">
        <Col  xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-0 ps-0 mt-2">
          <Image src={unentretienavecunchat2} fluid style={{width: '100%'}} alt="Un Entretien Avec Un Chat, Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="text-center p-0">
        <Col  xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-0 ps-0 mt-2">
          <Image src={unentretienavecunchat1} fluid style={{width: '100%'}} alt="Un Entretien Avec Un Chat, Manon Lambeens"/>
        </Col>
      </Row>
    </Layout>
)
export default UnEntretienAvecUnChat;